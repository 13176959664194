import React, { useEffect, useState } from 'react'
import CustomTable from '../../components/CustomTable/CustomTable'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { buttonsDiv, headerDiv, searchField, searchInput } from './styles'
import Page from '../../components/Page'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Upload from './components/Upload/Upload'
import './style.css'
import MappingModal from './components/MappingModal/MappingModal'
import {
  useGetFilesBySearchQuery,
  useGetFilesQuery
} from '../../RTKQuery/FileService/fileApi'
import { dashboardTableHeader } from '../../constants/componentConstants'
import ActionButton from './components/ActionButton/ActionButton'
import Loader from '../../components/Loader/Loader'
import CompareModal from './components/CompareModal/CompareModal'
import CsvTemplateButton from '../../components/UIKit/Buttons/DownloadTemplate'
import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import MuiSnackbar from '../../components/UIKit/Snackbar'

const Dashboard = () => {
  const [uploadModal, setUploadModal] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [compareModal, setCompareModal] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [uploadTypeApollo, setUploadTypeApollo] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [search, setSearch] = useState(false)
  const [showApollo, setShowApollo] = useState(false)

  const [rows, setRows] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  //Alert props
  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')

  // const navigate = useNavigate()
  const user = useSelector(state => state.auth?.user)
  const { data, isLoading, refetch } = useGetFilesQuery({
    page: currentPage,
    rows
  })

  const { data: searchResult, isLoading: searchQueryLoading } =
    useGetFilesBySearchQuery(
      {
        searchQuery,
        page: currentPage,
        rows
      },
      {
        skip: !search
      }
    )

  const handleSearch = () => {
    setSearch(true)
  }

  const handleSearchChange = e => {
    setSearch(false)
    setSearchQuery(e.target.value)
  }

  const handleUploadModal = bool => {
    setShowMap(bool)
    setUploadModal(true)
  }

  const handleUploadApollo = () => {
    setUploadTypeApollo(true)
    handleUploadModal(true)
  }

  const handleUploadUsers = () => {
    setUploadTypeApollo(false)
    handleUploadModal(true)
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  useEffect(() => {
    if (user && user.permissions) {
      const hasPermission = user.permissions.some(
        permission => permission.id === 1
      )
      if (hasPermission) {
        setShowApollo(true)
      }
    }
  }, [user])

  return (
    <Page>
      <Box sx={headerDiv}>
        <Typography sx={{ fontSize: '1.438rem', fontWeight: '600' }}>
          Dashboard
        </Typography>
        <Box sx={searchField}>
          <input
            type='text'
            style={searchInput}
            className='inputSearch'
            placeholder='Search by File Name or Tag...'
            onChange={handleSearchChange}
          />
          <IconButton onClick={handleSearch}>
            <img src='Research.png' alt='' />
          </IconButton>
        </Box>
        <Box sx={buttonsDiv}>
          {showApollo && (
            <Button
              startIcon={<UploadFileIcon />}
              color='primary'
              variant='contained'
              sx={{ color: '#F3F3F3' }}
              onClick={handleUploadApollo}
            >
              Upload Apollo
            </Button>
          )}
          <Button
            startIcon={<UploadFileIcon />}
            color='primary'
            variant='contained'
            sx={{ color: '#F3F3F3' }}
            onClick={handleUploadUsers}
          >
            Upload Csv
          </Button>
          <CsvTemplateButton />
        </Box>
      </Box>
      {isLoading || searchQueryLoading ? (
        <div className='noData'>
          <Loader />
        </div>
      ) : data?.files && data.files.length > 0 ? (
        <CustomTable
          data={searchResult?.files || data?.files}
          label={dashboardTableHeader}
          view
          action={<ActionButton />}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={data.total || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
          // showId = {fa}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
      <Upload
        open={uploadModal}
        setOpen={setUploadModal}
        setMapModal={setMapModal}
        showMap={showMap}
        setShowMap={setShowMap}
        uploadTypeApollo={uploadTypeApollo}
        BtnText={uploadTypeApollo ? 'Upload Apollo Csv' : 'Upload Csv'}
        uploading={uploading}
        setUploading={setUploading}
        refetchData={refetch}
      />
      <MappingModal
        open={mapModal}
        setOpen={setMapModal}
        uploadTypeApollo={uploadTypeApollo}
        setUploading={setUploading}
        setUploadModal={setUploadModal}
        setShowAlert={setShowAlert}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />
      <MuiSnackbar
        show={showAlert}
        setShow={setShowAlert}
        severity={severity}
        message={message}
      />
      <CompareModal open={compareModal} setOpen={setCompareModal} />
    </Page>
  )
}

export default Dashboard
