import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Modal,
  Snackbar,
  Typography
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  cancelContainer,
  divider,
  headerContainer,
  mainContainer,
  textStyles,
  uploadContainer,
  uploadingTextStyle
} from './styles'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ProgressBar from '../../../../components/ProgressBar/ProgressBar'
import {
  useUpdateApolloFileMutation,
  useUploadFileMutation
} from '../../../../RTKQuery/FileService/fileApi'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFileData,
  setFileHeaders,
  setUploadedFile,
  setFileRepeating,
  setUploadedLemlistFile
} from '../../../../RTKQuery/FileService/fileSlice'
import {
  useUploadLemlistFileMutation,
  useUploadSendgridFileMutation
} from '../../../../RTKQuery/FileService/fileApi'
import { useNavigate } from 'react-router-dom'

const Upload = ({
  open,
  setOpen,
  setMapModal,
  uploading,
  setUploading,
  showMap,
  campaignId,
  sendGrid = false,
  uploadTypeApollo,
  setShowAlert,
  setSeverity,
  setMessage
}) => {
  const updateApolloFileMutation = useUpdateApolloFileMutation()
  const uploadFileMutation = useUploadFileMutation()

  const inputRef = useRef('rawData')
  const dispatch = useDispatch()
  const [uploadFile] = uploadTypeApollo
    ? updateApolloFileMutation
    : uploadFileMutation
  const [
    uploadLemlistFile,
    { isSuccess: lemlistSuccess, isError: lemlistError }
  ] = useUploadLemlistFileMutation()
  const [
    uploadSendgridFile,
    { isSuccess: sendGridSuccess, isError: sendGridError }
  ] = useUploadSendgridFileMutation()
  const [fileName, setFileName] = useState(false)
  const [error, setError] = useState('')
  const { progress } = useSelector(state => state.file)
  const navigate = useNavigate()

  const fileUploadComplete = () => {
    if (!sendGrid) {
      setMapModal(showMap)
    }
    if (sendGrid) {
      setUploading(false)
    }
    // setUploaded(true)
    setOpen(false)
    if (!sendGrid && !showMap) {
      navigate(-1)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleUpload = () => {
    !uploading && inputRef.current.click()
  }
  const handleDrag = e => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleDrop = event => {
    event.preventDefault()
    setError('')
    const files = Array.from(event.dataTransfer.files)
    if (files[0]?.type === 'text/csv') {
      handleFileUpload(files[0])
    } else {
      setError('Please only upload csv files')
    }
  }
  const handleFileChange = e => {
    setFileName(e.target.files[0].name)
    if (showMap) {
      handleFileUpload(e.target.files[0])
    } else {
      handleCampaignStatsUpload(e.target.files[0])
    }
  }

  //Upload file and cache the response data to show in mapping modal
  const handleFileUpload = async file => {
    const formData = new FormData()
    formData.append('file', file)
    setUploading(true)

    try {
      const {
        data: { file, fileData, fileHeaders, redundantEmails }
      } = await uploadFile(formData)
      dispatch(setUploadedFile(file))
      dispatch(setFileData(fileData))
      dispatch(setFileRepeating(redundantEmails))
      dispatch(setFileHeaders(fileHeaders))
    } catch (error) {
      console.log(error)
    }
  }
  // cmpaign stats uploader, no mapping modal after this
  const handleCampaignStatsUpload = async file => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('campaignId', campaignId)

    setUploading(true)
    try {
      if (sendGrid) {
        const { data } = await uploadSendgridFile(formData)
        // dispatch(setUploadedLemlistFile(file))
        dispatch(setFileData(data))
        // dispatch(setFileRepeating(redundantEmails))
        // dispatch(setFileHeaders(fileHeaders))
      } else {
        const {
          data: { file, fileData, fileHeaders, redundantEmails }
        } = await uploadLemlistFile(formData)
        dispatch(setUploadedLemlistFile(file))
        dispatch(setFileData(fileData))
        dispatch(setFileRepeating(redundantEmails))
        dispatch(setFileHeaders(fileHeaders))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleErrorClose = () => {
    setError('')
  }
  useEffect(() => {
    if (progress === 100 && uploading) {
      fileUploadComplete()
    }
  })
  // Handle alert messages for stats upload
  useEffect(() => {
    if (lemlistError) {
      setMessage('Lemlist file upload failed')
      setSeverity('error')
      setShowAlert(true)
    } else if (sendGridError) {
      setMessage('SendGrid file upload failed')
      setSeverity('error')
      setShowAlert(true)
    } else if (lemlistSuccess) {
      setMessage('Lemlist file uploaded successfully!')
      setSeverity('success')
      setShowAlert(true)
    } else if (sendGridSuccess) {
      setMessage('SendGrid file uploaded successfully!')
      setSeverity('success')
      setShowAlert(true)
    }
  }, [lemlistError, sendGridError, lemlistSuccess, sendGridSuccess])

  return (
    <Modal
      open={open}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Container sx={mainContainer}>
        <Box sx={headerContainer}>
          <Typography sx={textStyles}>
            {!showMap
              ? 'Upload Campaign Stats'
              : uploadTypeApollo
              ? 'Upload Apollo Csv'
              : 'Upload Csv'}
          </Typography>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon />
          </IconButton>
        </Box>

        <Box
          sx={uploadContainer}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          accept='*'
          onClick={handleUpload}
        >
          <UploadFileIcon
            sx={{ width: '3rem', height: '3rem', color: '#808080' }}
          />
          {!uploading ? (
            <Typography sx={textStyles}>
              Drop or Click here to upload {uploadTypeApollo && 'apollo'} CSV
            </Typography>
          ) : (
            <Box onClick={fileUploadComplete}>
              <Typography sx={textStyles} color={'primary'}>
                {fileName}
              </Typography>
              <ProgressBar progress={progress} />
              <Typography sx={uploadingTextStyle}>
                Uploading CSV for mapping...
              </Typography>
            </Box>
          )}
          <input
            type='file'
            name='file'
            onChange={handleFileChange}
            accept='.csv'
            multiple={false}
            style={{ display: 'none' }}
            ref={inputRef}
          />
        </Box>
        <Divider sx={divider} />
        <Box sx={cancelContainer}>
          <Button color='info' variant='contained' onClick={handleClose}>
            Cancel
          </Button>
        </Box>
        <Snackbar
          open={error ? true : false}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          action={
            <IconButton onClick={handleErrorClose}>
              <HighlightOffIcon />
            </IconButton>
          }
        >
          <Alert severity='error' sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </Modal>
  )
}

export default Upload
